import { UserModel } from './../_models/user.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(public http: HttpClient) { }

  get(id: number): Observable<UserModel> {
    return this.http.get('customer/' + id)
      .map(
        (response: any) => {
          return <UserModel>response;
        }
      );
  }

  getAllRecords(): Observable<UserModel[]> {
    return this.http.get('customer')
      .map(
        (response: any) => {
          return <UserModel[]>response;
        }
      );
  }
}
